import {
  FETCH_POST_PAGE_DATA_SUCCESS,
  fetchPostPageDataSuccess,
} from '../../actions/fetch-post-page-data';
import { FETCH_POSTS_SUCCESS, fetchPostsSuccess } from '../../actions/fetch-posts';
import { PostActivityState } from './post-activity-state';

const initialState: PostActivityState = {
  newPosts: [],
};

type Post = {
  _id: string;
  isNew?: boolean;
};

type Actions = ReturnType<typeof fetchPostsSuccess> | ReturnType<typeof fetchPostPageDataSuccess>;

export function postActivity(state = initialState, action: Actions) {
  switch (action.type) {
    case FETCH_POSTS_SUCCESS:
      return {
        newPosts: Array.from(
          new Set([
            ...state.newPosts,
            ...action.payload.filter((p: Post) => p.isNew === true).map((p: Post) => p._id),
          ]),
        ),
      };
    case FETCH_POST_PAGE_DATA_SUCCESS:
      return {
        newPosts: state.newPosts.filter(id => id !== action.payload.result.post._id),
      };
    default:
      return state;
  }
}
