import { reduce, toString } from 'lodash';
import { extractHashtagsFromText } from '@wix/communities-forum-client-commons/dist/src/services/hashtag-utils';

export const shouldSearchByHashtag = (query: string): boolean => {
  const hashtags = extractHashtagsFromText(query);
  return !!query &&
  !reduce(
    hashtags,
    (prevValue, hashTag) => prevValue.replace(`#${hashTag}`, ''),
    toString(query),
  ).replace(/\s|,/g, '');
};
