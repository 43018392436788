import createAction from '../../common/services/create-action';

export const MARK_POST_AS_UNREAD_REQUEST = 'post/MARK_POST_AS_UNREAD_REQUEST';
export const MARK_POST_AS_UNREAD_SUCCESS = 'post/MARK_POST_AS_UNREAD_SUCCESS';
export const MARK_POST_AS_UNREAD_FAILURE = 'post/MARK_POST_AS_UNREAD_FAILURE';

const markPostAsReadRequest = createAction(MARK_POST_AS_UNREAD_REQUEST);
const markPostAsReadSuccess = createAction(MARK_POST_AS_UNREAD_SUCCESS);
const markPostAsReadFailure = createAction(MARK_POST_AS_UNREAD_FAILURE);

export function markPostAsUnread(postId) {
  return (dispatch, getState, { request }) => {
    dispatch(markPostAsReadRequest({ postId }));

    const promise = request.put(`/platformized/v1/posts/${postId}/mark-unread`);
    return promise
      .then(
        () => dispatch(markPostAsReadSuccess({ postId })),
        error => dispatch(markPostAsReadFailure({ postId }, error)),
      )
      .then(() => promise);
  };
}
