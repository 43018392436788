import { RootState } from '../reducers/root-state';
import { syncPager } from '../actions/comments-pagination-actions';
import type { initializeCommentsController } from '@wix/comments-ooi-client/controller';
import { shouldShowCaptcha } from '../actions/create-comment';
import { userEventsCommentCaptchaShown, userEventsCommentCaptchaResolved } from '../actions/user-events';
import { getSpamWords, getFilteredWords } from '../selectors/forum-data-selectors';
import { RicosContent } from 'ricos-content';

// TODO: use return type of createReduxStore from createForumAppController.js when it's refactored
type RootStore = {
  getState: () => RootState;
  dispatch: (action: any) => void;
};

type PostPagePaginationParamsStore = {
  value: any;
  update: (newValue: any) => void;
};

type Awaited<T> = T extends PromiseLike<infer U> ? U : T;

export const configureCommentsApi = ({
  wixCommentsApi,
  wixCodeApi,
  store,
  postPagePaginationParamsStore,
}: {
  wixCommentsApi: Awaited<ReturnType<typeof initializeCommentsController>>;
  wixCodeApi: any;
  store: RootStore;
  postPagePaginationParamsStore: PostPagePaginationParamsStore;
}) => {
  wixCommentsApi.watch.pagination.onChange(paginationState => {
    store.dispatch(syncPager(paginationState, postPagePaginationParamsStore?.value?.params));
  });

  wixCommentsApi.intercept.onBeforeCrud(async (operation) => {
    const state = store.getState();

    if (shouldShowCaptcha(state, operation.content)) {
      const token = await getCaptchaToken();

      if (!token) {
        return {
          type: 'ERROR',
          message: { type: 'SILENT' },
        }
      }
    }

    const spamWords: string[] = getSpamWords(state);

    if (contentContainsSpamWords(operation.content, spamWords)) {
      return {
        type: 'ERROR',
        message: {
          type: 'EXTERNAL_TRANSLATION',
          key: 'messages.comment-invalid',
        },
      };
    }

    const filteredWords: string[] = getFilteredWords(state);

    return {
      type: 'OK',
      content: replaceContentWithFilteredWords(operation.content, filteredWords),
    };
  });

  const getCaptchaToken = async (): Promise<string> => {
    store.dispatch(userEventsCommentCaptchaShown());
    const token = await wixCodeApi.authentication.openCaptchaChallenge();
    store.dispatch(userEventsCommentCaptchaResolved(!!token));

    return token;
  }

  const contentContainsSpamWords = (content: RicosContent, spamWords: string[]): boolean => {
    return content.blocks.some((b) => spamWords.some(word => b.text.includes(word)));
  }

  const replaceContentWithFilteredWords = (content: RicosContent, filteredWords: string[]): RicosContent => {
    return {
      ...content,
      blocks: content.blocks.map((b) => {
        return {
          ...b,
          text: b.text.split(/\b/).map(word => {
            if (filteredWords.indexOf(word.toLowerCase()) > -1) {
              return '*'.repeat(word.length);
            }
            return word;
          }).join('')
        };
      }),
    };
  }
};
